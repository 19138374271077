import { Box, Typography, Divider, TextField, styled } from '@material-ui/core'
import { Button } from 'components'
import { useState, useEffect } from 'react'

import { Modal } from 'shared'
import { useStyles } from 'pages/Admin/Config/SeparationGroup/SeparationGroupConfig/styles'
import logoIfood from 'assets/icons/logo-ifood.png'
import { IoStorefrontSharp, IoHome } from 'react-icons/io5'
import { FiAlertTriangle } from 'react-icons/fi'
import { FaTruckLoading } from 'react-icons/fa'
import {
  ISeparationGroupConfig,
  ISeparationGroupConfigRequest,
} from 'interfaces/ISeparationGroupService'
import { usePopup } from 'hooks/usePopup'
import CheckboxConfig from '../CheckboxConfig'
import {
  MdOutlinePrecisionManufacturing,
  MdPendingActions,
  MdQrCodeScanner,
  MdScale,
} from 'react-icons/md'
import { BsBoxSeam, BsCheck2All } from 'react-icons/bs'
import { ContainerCheckboxs } from 'pages/Admin/Config/SeparationGroup/styles'
import ModalStepScanner, {
  IStepsSelected,
} from 'pages/Admin/Config/SeparationGroup/ModalStepScanner'

interface IProps extends ISeparationGroupConfig {
  isOpen: boolean
  handleCloseModal: () => void
  submitNewSeparationGroup: (
    params: ISeparationGroupConfigRequest,
    type: 'create' | 'update',
  ) => Promise<void>
  marketsNumber: number[]
}

export const ButtonSecondary = styled(Button)({
  background: '#FFF',
  border: '2px solid #618619',
  '& p': {
    color: '#618619',
    width: '100%',
  },
})

export const NewSeparationGroup = ({
  isOpen,
  handleCloseModal,
  submitNewSeparationGroup,
  marketsNumber,
  ...rest
}: IProps) => {
  const classes = useStyles()
  const {
    separation_group,
    receive_pj_orders,
    receive_pf_orders,
    receive_ka_orders,
    receive_critical_orders,
    receive_ifood_orders,
    receive_dinamic_price_orders,
    separation_group_commands,
    has_conference,
    cam_scanner,
    infrared_scanner,
    has_count_by_step,
    steps_without_scanner,
    market_number,
  } = rest
  const { addPopup } = usePopup()
  const [separationGroupNew, setSeparationGroupNew] = useState('')
  const [isPJ, setIsPJ] = useState(receive_pj_orders)
  const [isPF, setIsPF] = useState(receive_pf_orders)
  const [isKA, setIsKA] = useState(receive_ka_orders)
  const [isCritical, setIsCritical] = useState(receive_critical_orders)
  const [isIfood, setIsIfood] = useState(receive_ifood_orders)
  const [isDinamicPrice, setIsDinamicPrice] = useState(receive_dinamic_price_orders)
  const [isDirected, setIsDirected] = useState(separation_group_commands)
  const [isCamScanner, setIsCamScanner] = useState(cam_scanner)
  const [hasConference, setHasConference] = useState(has_conference)
  const [isInfraredScanner, setIsInfraredScanner] = useState(infrared_scanner)
  const [hasCountByStep, setHasCountByStep] = useState(has_count_by_step)
  const [stepsWithoutScanner, setStepsWithoutScanner] = useState(steps_without_scanner || [])
  const [marketNumber, setMarketNumber] = useState(0)

  const [modalStepScanner, setModalStepScanner] = useState({
    open: false,
    callback: null as any as (steps: IStepsSelected) => void,
  })

  const handleSubmitForm = async () => {
    try {
      await submitNewSeparationGroup(
        {
          separationGroup: separationGroupNew,
          camScanner: isCamScanner,
          fastTrack: true,
          pjOrders: isPJ,
          pfOrders: isPF,
          kaOrders: isKA,
          criticalOrders: isCritical,
          ifoodOrders: isIfood,
          dinamicPriceOrders: isDinamicPrice,
          separationGroupCommands: isDirected,
          hasConference: hasConference,
          infraredScanner: isInfraredScanner,
          hasCountByStep: hasCountByStep,
          stepsWithoutScanner,
          marketNumber,
        },
        'create',
      )

      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Solicitação concluída',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro para criar grupo de separação',
        description: error?.message || error?.msg || 'Contate o time de tech',
      })
    }
  }

  const handleInfraredScanner = (value: boolean) => {
    if (value && isCamScanner) {
      setIsCamScanner(false)
      setIsInfraredScanner(true)
      return
    }
    setIsInfraredScanner(value)
  }

  const handleCamScanner = (value: boolean) => {
    if (value && isInfraredScanner) {
      setIsInfraredScanner(false)
      setIsCamScanner(true)
      return
    }
    setIsCamScanner(value)
  }

  const onOpenModalStepScanner = () => {
    setModalStepScanner({
      open: true,
      callback: (steps: IStepsSelected) => {
        const stepsNotSelected = steps.filter(step => !step[Number(Object.keys(step)[0])])
        setStepsWithoutScanner(stepsNotSelected.map(step => Number(Object.keys(step)[0])))
        setModalStepScanner({
          open: false,
          callback: () => null as any as (steps: IStepsSelected) => void,
        })
      },
    })
  }

  const handleDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  const handleInverseDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  useEffect(() => {
    setSeparationGroupNew(separation_group)
    setIsPJ(receive_pj_orders || false)
    setIsPF(receive_pf_orders || false)
    setIsKA(receive_ka_orders || false)
    setIsIfood(receive_ifood_orders || false)
    setIsCritical(receive_critical_orders || false)
    setIsDinamicPrice(receive_dinamic_price_orders || false)
    setIsDirected(separation_group_commands || false)
    setIsCamScanner(cam_scanner || false)
    setHasConference(has_conference || false)
    setIsInfraredScanner(infrared_scanner || false)
    setHasCountByStep(has_count_by_step || false)
    setStepsWithoutScanner(steps_without_scanner || [])
    setMarketNumber(market_number)
  }, [separation_group])

  useEffect(() => {
    if (!isOpen) {
      setMarketNumber(0)
    }
  }, [isOpen])

  return (
    <Modal title="Grupo de separação novo? Aí sim! 🤠" handleClose={handleCloseModal} open={isOpen}>
      <Typography className={classes.separationGroupNumber}>
        Pra começar chique, já digita aí qual vai ser o número do novo grupo:
      </Typography>
      <Box
        mt="1rem"
        onSubmit={handleSubmitForm}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          style={{ width: '155px', textAlign: 'center' }}
          label="Número do grupo"
          variant="outlined"
          type="number"
          onChange={e => setSeparationGroupNew(e.target.value)}
          value={separationGroupNew}
          defaultValue={null}
          inputProps={{
            pattern: '[0-9999]*',
            inputMode: 'numeric',
          }}
        />
      </Box>
      <Box
        mt="1rem"
        width="100%"
        borderRight="1rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.separationGroupNumber}>
          Aoba! Agora fala pra mim quais tipos de pedidos esse grupo vai separar:
        </Typography>
      </Box>
      <Box>
        <ContainerCheckboxs>
          <CheckboxConfig
            checked={isDirected}
            onChange={handleDirectedChange}
            text="O grupo manda"
            icon={MdOutlinePrecisionManufacturing}
          />
          <CheckboxConfig
            checked={!isDirected}
            onChange={handleInverseDirectedChange}
            text="Eu que mando"
            icon={MdPendingActions}
          />
        </ContainerCheckboxs>
      </Box>
      <Divider className={classes.divider} />
      <ContainerCheckboxs>
        <CheckboxConfig
          checked={isPJ}
          onChange={setIsPJ}
          text="Pedidos PJ"
          icon={IoStorefrontSharp}
          disable={!isDirected}
        />
        <CheckboxConfig
          checked={isCritical}
          onChange={setIsCritical}
          text="Pedidos Críticos"
          icon={FiAlertTriangle}
          disable={!isDirected}
          isRed
        />

        <CheckboxConfig
          checked={isPF}
          disable={!isDirected}
          onChange={setIsPF}
          text="Pedidos PF"
          icon={IoHome}
        />
        <CheckboxConfig
          checked={isIfood}
          onChange={setIsIfood}
          text="Pedidos IFood"
          disable={!isDirected}
          image={logoIfood}
        />
        <CheckboxConfig
          checked={isKA}
          disable={!isDirected}
          onChange={setIsKA}
          text="Pedidos KA"
          icon={FaTruckLoading}
        />
        <CheckboxConfig
          checked={isDinamicPrice}
          onChange={setIsDinamicPrice}
          text="Pedidos Extra Order"
          disable={!isDirected}
          icon={MdScale}
        />
      </ContainerCheckboxs>

      <Divider className={classes.divider} />

      <ContainerCheckboxs>
        <CheckboxConfig
          checked={isCamScanner}
          onChange={handleCamScanner}
          text="Leitor camera"
          icon={MdQrCodeScanner}
        />
        <CheckboxConfig
          checked={hasConference}
          onChange={setHasConference}
          text="Revisão"
          icon={BsCheck2All}
        />
        <CheckboxConfig
          checked={isInfraredScanner}
          onChange={handleInfraredScanner}
          text="Leitura infra-vermelho"
          icon={MdQrCodeScanner}
        />
        <CheckboxConfig
          checked={hasCountByStep}
          onChange={setHasCountByStep}
          text="Contagem por etapa"
          icon={BsBoxSeam}
        />
      </ContainerCheckboxs>
      <Divider className={classes.divider} />
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Typography className={classes.separationGroupNumber} style={{ marginBottom: '0.5rem' }}>
          Fala pra gente qual o mercado que esse grupo vai pertencer:
        </Typography>
        <select
          value={marketNumber || ''}
          onChange={event => setMarketNumber(Number(event.target.value))}
          className={classes.select}
        >
          <option value="" disabled>
            Selecione um mercado
          </option>
          {marketsNumber.map((market, index) => (
            <option key={index} value={market}>
              Mercado {market}
            </option>
          ))}
        </select>
      </Box>
      <Box display="flex" justifyContent="space-between">
        {(isCamScanner || isInfraredScanner) && (
          <ButtonSecondary
            className={classes.buttonConfigs}
            onClick={onOpenModalStepScanner}
            style={{ marginLeft: 0 }}
          >
            <Typography className={classes.buttonText}>Etapas com leitor</Typography>
          </ButtonSecondary>
        )}

        <Button
          variant="contained"
          className={classes.buttonConfigs}
          onClick={handleSubmitForm}
          disabled={!marketNumber || !separationGroupNew}
        >
          <Typography className={classes.buttonText}>Certin, pode criar!</Typography>
        </Button>
      </Box>

      {modalStepScanner.open && (
        <ModalStepScanner
          open={modalStepScanner.open}
          onConfirm={modalStepScanner.callback}
          steps_without_scanner={stepsWithoutScanner}
          onClose={() => {
            setModalStepScanner({
              open: false,
              callback: () => null as any as (steps: IStepsSelected) => void,
            })
          }}
        />
      )}
    </Modal>
  )
}
