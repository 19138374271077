import { Tab, Tabs, Box, Typography, Divider, styled, LinearProgress } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { ChangeEvent, useState, useEffect } from 'react'
import { Modal } from 'shared'

import { useStyles } from './styles'
import { separatorService } from 'services/separatorService'
import DateRangerPicker from 'components/DateRangerPicker'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
}

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

export const DownloadExcelAnalysis = ({ isOpen, handleCloseModal }: IProps) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'days').toDate())
  const [endDate, setEndDate] = useState<Date>(moment().subtract(1, 'days').toDate())
  const [isValidRange, setIsValidRange] = useState(true)

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const date = (param: Date): string => {
    return moment(param).format('YYYY-MM-DD')
  }

  const validateDateRange = (start: Date, end: Date) => {
    const today = moment().startOf('day').toDate()
    const dayDifference = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)

    if (
      (start.toDateString() !== today.toDateString() &&
        start.toDateString() === end.toDateString()) ||
      (start.toDateString() !== today.toDateString() && dayDifference <= 6)
    ) {
      setIsValidRange(true)
    } else {
      setIsValidRange(false)
    }
  }

  const handleDateChange = (range: { startDate: Date | null; endDate: Date | null }) => {
    const newStartDate = range.startDate || moment().subtract(1, 'days').toDate()
    const newEndDate = range.endDate || moment().subtract(1, 'days').toDate()
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    validateDateRange(newStartDate, newEndDate)
  }

  const handleDownloadExcelTimeToSeparation = async () => {
    if (!startDate || !endDate || !isValidRange) {
      addPopup({
        type: 'error',
        title: 'Datas inválidas 😢',
        description: 'Por favor, selecione uma data inicial e final válidas para continuar.',
      })
      return
    }
    setIsLoading(true)
    try {
      const data = await separatorService.getAverageTimeItemSeparatorsExcel(
        date(startDate),
        date(endDate),
      )
      fileDownload(data, 'Contagem_de_caixas.xls')
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download foi solicitado com sucesso 🤠',
      })
      setStartDate(moment().subtract(1, 'days').toDate())
      setEndDate(moment().subtract(1, 'days').toDate())
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExcelOrdersBySeparator = async () => {
    if (!startDate || !endDate) {
      addPopup({
        type: 'error',
        title: 'Datas inválidas 😢',
        description: 'Por favor, selecione uma data inicial e final para continuar.',
      })
      return
    }
    setIsLoading(true)
    try {
      const data = await separatorService.getOrdersBySeparatorExcel(date(startDate), date(endDate))
      fileDownload(data, 'Pedidos_por_separador.xls')
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download foi solicitado com sucesso 🤠',
      })
      setStartDate(moment().subtract(1, 'days').toDate())
      setEndDate(moment().subtract(1, 'days').toDate())
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setStartDate(moment().subtract(1, 'days').toDate())
      setEndDate(moment().subtract(1, 'days').toDate())
      setIsValidRange(true)
    }
  }, [isOpen])

  return (
    <Modal title="Baixar uma planilha? Simbora! 🤠" handleClose={handleCloseModal} open={isOpen}>
      <Tabs value={tab} onChange={handleChangeTab} centered>
        <Tab label="Tempo médio por pedido" />
        <Tab label="Pedidos por separador" />
      </Tabs>
      <Divider style={{ margin: '1rem 0' }} />
      {tab === 0 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, aqui num tem segredo nenhum, só marcar a data inicial e final da operação e baixar!
          </Typography>
          <LinearProgress
            variant="indeterminate"
            style={{ width: '100%', opacity: isLoading ? 1 : 0 }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', margin: '1rem 0' }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '300px',
                marginTop: '1rem',
              }}
            >
              <DateRangerPicker
                onSelectedDate={handleDateChange}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelTimeToSeparation}
            disabled={!isValidRange || isLoading}
          >
            <Typography className={classes.buttonText}>Pode baixar!</Typography>
          </Button>
        </Box>
      )}
      {tab === 1 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, aqui num tem segredo nenhum, só marcar a data inicial e final da operação e baixar!
          </Typography>
          <LinearProgress
            variant="indeterminate"
            style={{ width: '100%', opacity: isLoading ? 1 : 0 }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', margin: '1rem 0' }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '300px',
                marginTop: '1rem',
              }}
            >
              <DateRangerPicker
                onSelectedDate={handleDateChange}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelOrdersBySeparator}
            disabled={!isValidRange || isLoading}
          >
            <Typography className={classes.buttonText}>Pode baixar!</Typography>
          </Button>
        </Box>
      )}
      {!isValidRange && (
        <Typography color="error" style={{ marginTop: '1rem', textAlign: 'center' }}>
          Não selecione a data de hoje e nem um intervalo maior que uma semana completa (Domingo a
          Sexta).
        </Typography>
      )}
    </Modal>
  )
}
